import styled from "styled-components";

import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";

export const PostPageWrapper = styled.section`
  padding-bottom: 50px;
  @media only screen and (max-width: 480px) {
    padding-bottom: 20px;
  }
  .back {
    display: flex;
    max-width: 200px;
    margin: 40px 0 37px;
    @media only screen and (max-width: 480px) {
      margin: 38px 0 34px;
    }
    p {
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.05em;
      font-weight: bold;
      color: #2f3137;
      padding-left: 14px;
      padding-bottom: 0;
      text-decoration: none;
    }
  }
  .gatsby-image-wrapper {
    max-width: 864px;
    height: 421px;
    margin: 0 auto;
    @media only screen and (max-width: 883px) {
      max-width: 100%;
      height: 289px;
      margin: 0 80px;
    }
    @media only screen and (max-width: 600px) {
      height: 133px;
      margin: 0 24px;
    }
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
  .postInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 0 36px;
    @media only screen and (max-width: 883px) {
      padding: 24px 0 54px;
    }
    @media only screen and (max-width: 600px) {
      padding: 18px 0 34px;
    }
    .categoryInfo {
      padding: 8px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 12px;
      line-height: 100%;
      color: rgba(255, 255, 255, 0.7);
    }
    .dateInfo {
      font-size: 16px;
      line-height: 100%;
      text-align: right;
      color: #d4d5d7;
    }
  }
  h1 {
    font-weight: normal;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #272729;
    padding-bottom: 48px;
    @media only screen and (max-width: 480px) {
      font-size: 24px;
      padding-bottom: 25px;
    }
  }
  h2 {
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #272729;
    padding-bottom: 24px;
    margin-top: 40px;
    @media only screen and (max-width: 480px) {
      font-size: 20px;
      padding-bottom: 15px;
      margin-top: 32px;
    }
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.1em;
    color: #6c6e74;
    padding-bottom: 20px;
    @media only screen and (max-width: 480px) {
      padding-bottom: 10px;
    }
  }
  a {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.1em;
    color: #14141e;
    text-decoration: underline;
  }
  li {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.1em;
    color: #6c6e74;
    padding-bottom: 20px;
    @media only screen and (max-width: 480px) {
      padding-bottom: 10px;
    }
  }
  .blockHorizontal {
    width: 100%;
    align-items: center;
    margin: 21px 0 14px;
    a {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.03em;
      color: #ffffff;
      text-decoration: none;
      display: block;
    }
  }
`;

export const ArrowBlack = styled(ArrowBack)`
  color: #69cf67;
  width: 20px;
  height: 20px;
`;
