import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import * as S from "../styles/postPage/styles.js";

export const BlogPostTemplate = ({
  image,
  content,
  contentComponent,
  title,
  publicationDate,
  background,
  category,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <S.PostPageWrapper>
      {helmet || ""}
      <div className="containerPost">
        <Link to="/blog" className="back">
          <S.ArrowBlack />
          <p>Voltar para o blog</p>
        </Link>
      </div>
      <div className="conteinerImg">
        <Img fluid={image} />
      </div>
      <div className="containerPost">
        <div className="postInfo">
          <span
            className="categoryInfo"
            style={{ backgroundColor: background }}
          >
            {category}
          </span>
          <span className="dateInfo">{publicationDate}</span>
        </div>
        <h1 className="">{title}</h1>
        <PostContent content={content} />
        <Link to="/blog" className="back">
          <S.ArrowBlack />
          <p>Voltar para o blog</p>
        </Link>
      </div>
    </S.PostPageWrapper>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        image={post.frontmatter.featuredimage.childImageSharp.fluid}
        title={post.frontmatter.title}
        publicationDate={post.frontmatter.publicationDate}
        category={post.frontmatter.category}
        background={post.frontmatter.background}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        publicationDate
        category
        background
        featuredimage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
